import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthAxios from "../../config/authAxios";
import apiRoutes from "../apiConfigs";

export const moduleListingApi = createAsyncThunk(
  "auth/moduleListing",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AuthAxios.get(
        apiRoutes.channelAction('module/list'),
        { params: data }
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
const initialState = { result: [], loading: false, error: null, message: "", total: 0 }

const moduleListingSlice = createSlice({
  name: "moduleListing",
  initialState,
  reducers: {
    resetModuleListing: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(moduleListingApi.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(moduleListingApi.fulfilled, (state, action) => {
      state.loading = false;
      state.result = action?.payload?.results?.result;
      state.message = action?.payload?.message;
      state.total = action?.payload?.count
    });
    builder.addCase(moduleListingApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error;
      state.message = action?.payload?.message;
    });
  },
});
export const { resetModuleListing } = moduleListingSlice.actions;
export const moduleListingreducer = moduleListingSlice.reducer;
