import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthAxios from "../../config/authAxios";
import apiRoutes from "../apiConfigs";

export const assignedObjectListApi = createAsyncThunk(
  "auth/assignedObjectList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AuthAxios.get(
        apiRoutes.channelAction('assign-object/list'),
        { params: data }
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
const initialState={ result: [], loading: false, error: null, message: "", total: 0 }
const assignedObjectListSlice = createSlice({
  name: "assignedObjectList",
  initialState,
  reducers: {
    resetAssignedObjectList: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(assignedObjectListApi.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(assignedObjectListApi.fulfilled, (state, action) => {
      state.loading = false;
      state.result = action?.payload?.results?.result;
      state.message = action?.payload?.message;
      state.total = action?.payload?.count
    });
    builder.addCase(assignedObjectListApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error;
      state.message = action?.payload?.message;
    });
  },
});

export const { resetAssignedObjectList } = assignedObjectListSlice.actions;
export const assignedObjectListreducer = assignedObjectListSlice.reducer;
