import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthAxios from "../../config/authAxios";
import apiRoutes from "../apiConfigs";

export const channeltypeApi = createAsyncThunk(
  "auth/channeltypes",
  async (data, { rejectWithValue }) => {
    try {
      const paramsData = new URLSearchParams(data);
      const response = await AuthAxios.get(
        apiRoutes.channelTypeList, { params: data }
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const channeltypeSlice = createSlice({
  name: "channeltype",
  initialState: {
    result: [],
    loading: false,
    error: null,
    message: "",
    total: 0,
  },
  extraReducers: (builder) => {
    builder.addCase(channeltypeApi.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(channeltypeApi.fulfilled, (state, action) => {
      state.loading = false;
      state.result = action?.payload?.results;
      state.message = action?.payload?.message;
      state.total = action?.payload?.count;
    });
    builder.addCase(channeltypeApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error;
      state.message = action?.payload?.message;
    });
  },
});

export const channeltypeReducer = channeltypeSlice.reducer;
