import {
  Box,
  Button,
  Paper,
  Stack,
  Typography,
  Tabs,
  Tab,
  Card,
  Grid,
  MenuItem,
  FormHelperText,
  FormControl,
  InputLabel,
  Select
} from "@mui/material";
import { useEffect, useState, useCallback, useContext } from "react";
import { HiTemplate } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import Drawer from "../../components/Drawer";
import { useNavigate, useParams } from "react-router-dom";
import { analiticsFilterApi } from "../../store/analytics/filters";
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { classGlobalApi } from "../../store/class/classListGlobal";
import { subjectGlobalApi } from "../../store/subject/subjectListGlobal";
import apiRoutes from "../../store/apiConfigs";
import BackdropUI from "../../ui/Backdrop";
import DataGridComponent from "../../components/DataGridComponent";
import { assignedObjectListApi,resetAssignedObjectList } from "../../store/channel/assignedObjectsListing";
import { moduleListingApi,resetModuleListing } from "../../store/channel/moduleListing";
import { assignObjectToChannelApi } from "../../store/channel/assignObjectToChannel";
import { unAssignObjectFromChannelApi } from "../../store/channel/unAssignObjectFromChannel";
import { ThemeContext } from '../../context/ThemeContext';
import NodataImg from "../../assets/images/noData.png";
import CustomGridToolbar from "../../components/CustomGridToolbar";
import { getGridStringOperators } from "@mui/x-data-grid";
import dayjs from "dayjs";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from '@mui/icons-material/Delete';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function ChannelContent() {
  const navigate = useNavigate();

  const analyticsFilter = useSelector((state) => state?.analyticsFilter);
  const { result: filterResult, filterLoading } = analyticsFilter;
  const analyticsGraph = useSelector((state) => state?.analyticsGraph);
  const { result: graphResult, graphLoading } = analyticsGraph;
  const [notSelectedProductModel, setNotSelectedProductModel] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [error, setError] = useState(null);
  const { result: classList, loading: classLoading } = useSelector((state) => state?.classGlobal);
  const { result: subjectList, loading: subjectLoading } = useSelector((state) => state?.subjectGlobal);
  const { result: assignedObjectListResult, loading: assignedObjectLoading, total: assignedObjectTotal } = useSelector((state) => state?.assignedObjectList);
  const { result: moduleListingResult, loading: moduleListingLoading, total: moduleListingTotal } = useSelector((state) => state?.moduleListing);
  const { result: assignObjectToChannelResult, loading: assignObjectToChannelLoading } = useSelector((state) => state?.assignObjectToChannel);
  const { result: unAssignObjectFromChannelResult, loading: unAssignObjectFromChannelLoading } = useSelector((state) => state?.unAssignObjectFromChannel);
  const schema = yup
    .object({})
    .required();
  const schemaEdit = yup
    .object({})
    .required();
  const params = useParams();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(params.id ? schemaEdit : schema),
    defaultValues: {},
  });
  const [value, setValue] = useState(0);
  const [cat, setCat] = useState();
  const [classs, setClasss] = useState();
  const [subject, setSubject] = useState();
  const [state, setState] = useState();
  const [module, setModule] = useState("learningmaterial");
  const [nonGradedModule, setNonGradedModule] = useState("short_videos");
  const [communityModule, setCommunityModule] = useState("short_videos");
  const [isDownload, setIsDownload] = useState(false)
  const [reportType, setReportType] = useState("user_report");
  const [channelCategory, setChannelCategory] = useState("");
  const [monthDaily, setMonthDaily] = useState("monthly");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [region, setRegion] = useState();
  const categories = useSelector((state) => state?.categories);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [queryOptionsChangeAssignedModule, setQueryOptionsChangeAssignedModule] = useState({});
  const [queryOptionsChangeModuleListing, setQueryOptionsChangeModuleListing] = useState({});
  const [paginationModelChangeAssignedModule, setPaginationModelChangeAssignedModule] = useState({
    page: 0,
    pageSize: 20,
  });
  const [paginationModelChangeModuleListing, setPaginationModelChangeModuleListing] = useState({
    page: 0,
    pageSize: 20,
  });
  const onFilterChangeAssignedModule = useCallback((filterModel) => {
    setQueryOptionsChangeAssignedModule({ filterModel: { ...filterModel } });
  }, []);
  const onFilterChangeModuleListing = useCallback((filterModel) => {
    setQueryOptionsChangeModuleListing({ filterModel: { ...filterModel } });
  }, []);
  const getRowSpacing = useCallback((params) => {
    return {
      top: params.isFirstVisible ? 0 : 5,
      bottom: params.isLastVisible ? 0 : 5,
    };
  }, []);
  const createQueryParams = useCallback((paginationModel, queryOptions) => {
    const filterModel = queryOptions?.filterModel;
    const items = filterModel?.items;
    const firstItem = items && items.length > 0 ? items[0] : {};
    const quickFilterValues = filterModel?.quickFilterValues;

    return {
      page: paginationModel?.page + 1,
      page_size: paginationModel?.pageSize,
      field: firstItem.field || '',
      operator: firstItem.operator || (quickFilterValues && "contains") || '',
      search: firstItem.value || (quickFilterValues && quickFilterValues.toString().replaceAll(",", " ")) || '',
      channel_id: params.id,
      type: channelCategory
    };
  }, [params.id, channelCategory]);

  const callObjectList = useCallback(() => {
    const queryAssignedModule = createQueryParams(paginationModelChangeAssignedModule, queryOptionsChangeAssignedModule);
    dispatch(assignedObjectListApi(queryAssignedModule));
  }, [createQueryParams, dispatch, paginationModelChangeAssignedModule, queryOptionsChangeAssignedModule])

  const callModulelistList = useCallback(() => {
    const queryModuleListing = createQueryParams(paginationModelChangeModuleListing, queryOptionsChangeModuleListing);
    dispatch(moduleListingApi(queryModuleListing));
  }, [paginationModelChangeModuleListing, queryOptionsChangeModuleListing, createQueryParams, dispatch])

  useEffect(() => {
    if (value === 3) {
      dispatch(analiticsFilterApi({ type: "city" }));
    } else {
      dispatch(analiticsFilterApi({
        type: "filters", params: {
          start_date: startDate,
          end_date: endDate
        }
      }));

    }
  }, [value])

  const handleChannelCategory = (category) => {
    setChannelCategory(category)
  }

  const handleAddtoChannel = async (record) => {
    dispatch(assignObjectToChannelApi({
      type: record.module_type ? record.module_type === "Course" ? "Courses" : record.module_type : record.module_type,
      module_id: record.id,
      channel_id: params.id
    })).then((action) => {
      if (!action.type.includes("rejected")) {
        callObjectList()
        callModulelistList()
      }
    }).catch(err => console.log(err))

  }

  const handleRemoveFromChannel = async (record) => {
    dispatch(unAssignObjectFromChannelApi({
      type: record.module_type ? record.module_type === "Course" ? "Courses" : record.module_type : record.module_type,
      ID: record.id
    })).then((action) => {
      if (!action.type.includes("rejected")) {
        callObjectList()
      }
    }).catch(err => console.log(err))
  }

  const handlePublishUnpublishChannel = async (record) => {
    dispatch(assignObjectToChannelApi({
      type: record.row.module_type ? record.row.module_type === "Course" ? "Courses" : record.row.module_type : record.row.module_type,
      module_id: record.row.module_id,
      channel_id: params.id,
      published: !record.row.published
    })).then((action) => {
      if (!action.type.includes("rejected")) {
        callObjectList()
      }
    }).catch(err => console.log(err))
  }





  // Fetch global data
  useEffect(() => {
    dispatch(classGlobalApi());
    dispatch(subjectGlobalApi());
  }, [params.id, dispatch]);
  // Fetch assigned module data
  useEffect(() => {
    dispatch(resetAssignedObjectList())
    callObjectList()
  }, [callObjectList, paginationModelChangeAssignedModule, queryOptionsChangeAssignedModule]);
  // Fetch module listing data
  useEffect(() => {
    dispatch(resetModuleListing())
    callModulelistList()
  }, [callModulelistList, paginationModelChangeModuleListing, queryOptionsChangeModuleListing, channelCategory]);

  const moduleListingColumn = [
    {
      field: "title",
      headerName: "Title",
      width: 450,
      filterable: false,
      sortable: false,
      hideable: false
    },
    {
      field: "object_class",
      headerName: "Class",
      width: 450,
      filterable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'equals' | operator.value === 'contains',
      ),
      sortable: false,
      renderCell: (data) => (
        <>
          <Typography sx={{ textWrap: "wrap" }}>{Array.isArray(data?.row?.object_class) && data?.row?.object_class.map((obj, index) => (`${obj['title']}, `))}</Typography>
        </>),
      hideable: false
    },
    {
      field: "object_subjects",
      headerName: "Subject",
      width: 450,
      filterable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'contains',
      ),
      sortable: false,
      renderCell: (data) => (
        <>
          <Typography sx={{ textWrap: "wrap" }}>{Array.isArray(data?.row?.object_subjects) && data?.row?.object_subjects.map((obj, index) => (`${obj['title']}, `))}</Typography>
        </>),
      hideable: false
    },
    {
      field: "module_type",
      headerName: "Content Type",
      width: 200,
      filterable: false,
      sortable: false,
      hideable: false
    },
    {
      field: "actions",
      headerName: "Actions",
      filterable: false,
      sortable: false,
      width: 200,
      disableExport: true,
      renderCell: (data) => (
        <>
          <Button
            color="info"
            endIcon={<AddIcon />}
            label="Available"
            variant="outlined"
            sx={{ margin: "0px 10px" }}
            onClick={() => handleAddtoChannel(data.row)}
          >
            Add
          </Button>
        </>
      ),
    },
  ];
  const assignedObjectListColumn = [
    {
      field: "title",
      headerName: "Title",
      width: 450,
      filterable: false,
      sortable: false,
      hideable: false,
      renderCell: (data) => (<>
        {data.row.object_data.title}
      </>)
    },
    {
      field: "module_type",
      headerName: "Content Type",
      width: 200,
      filterable: false,
      sortable: false,
      hideable: false
    },
    {
      field: "actions",
      headerName: "Actions",
      filterable: false,
      sortable: false,
      width: 300,
      disableExport: true,
      renderCell: (data) => (
        <Box display={"flex"} justifyItems={"center"} alignItems={"center"} justifyContent={"space-around"} width={"100%"}>
          <Button color="success" variant="contained"
            endIcon={data?.row?.published ? <CheckIcon /> : <ArrowRightIcon />}
            onClick={() => handlePublishUnpublishChannel(data)}
          >
            {data?.row?.published ? "UnPublish" : "Publish"}
          </Button>
          <Button
            color="info"
            endIcon={<RemoveIcon />}
            label="Available"
            variant="outlined"
            onClick={() => handleRemoveFromChannel(data.row)}
          >
            Remove
          </Button>
        </Box>
      ),
    },
  ];
  return (
    <>
      <Appbar />
      <Drawer />
      <Body>

        <Stack direction="row" spacing={1} sx={{ my: 2 }} alignItems="center">
          <HiTemplate size={30} />
          <Typography>Channel Content</Typography>
        </Stack>
        <Stack>
          <Grid container gap={1}>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Card sx={{
                background: channelCategory === "" && "rgba(12, 174, 235, 0.04)"
              }}>
                <FormControl variant="outlined" fullWidth>
                  <Button onClick={() => {
                    handleChannelCategory("")
                  }}
                    color="primary"
                  >
                    All
                  </Button>
                </FormControl>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Card sx={{
                background: channelCategory === "Podcast" && "rgba(12, 174, 235, 0.04)"
              }}>
                <FormControl variant="outlined" fullWidth>
                  <Button onClick={() => {
                    handleChannelCategory("Podcast")
                  }}>
                    Podcast
                  </Button>
                </FormControl>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Card sx={{
                background: channelCategory === "Courses" && "rgba(12, 174, 235, 0.04)"
              }}>
                <FormControl variant="outlined" fullWidth>
                  <Button onClick={() => {
                    handleChannelCategory("Courses")
                  }}>
                    Courses
                  </Button>
                </FormControl>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Card sx={{
                background: channelCategory === "ShortVideos" && "rgba(12, 174, 235, 0.04)"
              }}>
                <FormControl variant="outlined" fullWidth>
                  <Button onClick={() => {
                    handleChannelCategory("ShortVideos")
                  }}>
                    ShortVideos
                  </Button>
                </FormControl>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Card sx={{
                background: channelCategory === "Webinar" && "rgba(12, 174, 235, 0.04)"
              }}>
                <FormControl variant="outlined" fullWidth>
                  <Button onClick={() => {
                    handleChannelCategory("Webinar")
                  }}>
                    Webinar
                  </Button>
                </FormControl>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Card sx={{
                background: channelCategory === "Quiz" && "rgba(12, 174, 235, 0.04)"
              }}>
                <FormControl variant="outlined" fullWidth>
                  <Button onClick={() => {
                    handleChannelCategory("Quiz")
                  }}>
                    Quiz
                  </Button>
                </FormControl>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Card sx={{
                background: channelCategory === "Polls" && "rgba(12, 174, 235, 0.04)"
              }}>
                <FormControl variant="outlined" fullWidth>
                  <Button onClick={() => {
                    handleChannelCategory("Polls")
                  }}>
                    Polls
                  </Button>
                </FormControl>
              </Card>
            </Grid>
          </Grid>
        </Stack>

        <Stack direction="row" spacing={1} sx={{ my: 2 }} alignItems="center">
          <Box>
            <Box margin={2}>
              <Typography variant="h3">Already added Channel Content</Typography>
            </Box>
            <Box margin={2}>
              <DataGridComponent
                loading={assignedObjectLoading}
                rowCount={assignedObjectTotal}
                pageSize={paginationModelChangeAssignedModule?.pageSize}
                onPageSizeChange={(newPageSize) => {
                  setPaginationModelChangeAssignedModule({
                    ...paginationModelChangeAssignedModule,
                    pageSize: newPageSize,
                  })
                }}
                paginationModel={paginationModelChangeAssignedModule}
                onPaginationModelChange={setPaginationModelChangeAssignedModule}
                sx={{
                  height: 500,
                }}
                getRowId={(row) => `${row.id}${row.module_id}${row.module_type}${uuidv4()}`}
                rows={assignedObjectListResult}
                getRowSpacing={getRowSpacing}
                columns={assignedObjectListColumn}
                onFilterModelChange={onFilterChangeAssignedModule}
                slots={{
                  noRowsOverlay: () => (
                    <Box className="noData">
                      <img src={NodataImg} alt="no data found" />
                    </Box>
                  ),
                  toolbar: (props) =>
                  (CustomGridToolbar({
                    ...props, moduleType: "assignedObject", queryOptions: queryOptionsChangeAssignedModule, paginationModel: paginationModelChangeAssignedModule, isDownload: isDownload, setIsDownload: setIsDownload, apiUrl: apiRoutes.channelAction('assign-object/list'), module: module,
                    region: region,
                    state: state
                  })),
                }}
                slotProps={{
                  loadingOverlay: {
                    variant: 'linear-progress',
                    noRowsVariant: 'linear-progress',
                  },
                  toolbar: {
                    showQuickFilter: true,
                  },
                }}
              />
            </Box>
          </Box>
        </Stack>
        
        <Stack direction="row" spacing={1} sx={{ my: 2 }} alignItems="center">
          <Box>
            <Box margin={2}>

              <Typography variant="h3">Add New Channel Content</Typography>
            </Box>
            <Box margin={2}>

              <DataGridComponent
                loading={moduleListingLoading}
                rowCount={moduleListingTotal}
                pageSize={paginationModelChangeModuleListing?.pageSize}
                onPageSizeChange={(newPageSize) => {
                  setPaginationModelChangeModuleListing({
                    ...paginationModelChangeModuleListing,
                    pageSize: newPageSize,
                  })
                }}
                paginationModel={paginationModelChangeModuleListing}
                onPaginationModelChange={setPaginationModelChangeModuleListing}
                sx={{
                  height: 500,
                }}
                getRowId={(row) => `${row.id}`}
                rows={moduleListingResult}
                getRowSpacing={getRowSpacing}
                columns={moduleListingColumn}
                onFilterModelChange={onFilterChangeModuleListing}
                slots={{
                  noRowsOverlay: () => (
                    <Box className="noData">
                      <img src={NodataImg} alt="no data found" />
                    </Box>
                  ),
                  toolbar: (props) =>
                  (CustomGridToolbar({
                    ...props, moduleType: "moduleListing", queryOptions: queryOptionsChangeModuleListing, paginationModel: paginationModelChangeModuleListing, isDownload: isDownload, setIsDownload: setIsDownload, apiUrl: apiRoutes.channelAction('module/list'), module: module,
                    region: region,
                    state: state
                  })),
                }}
                slotProps={{
                  loadingOverlay: {
                    variant: 'linear-progress',
                    noRowsVariant: 'linear-progress',
                  },
                  toolbar: {
                    showQuickFilter: true,
                  },
                }}
              />
            </Box>
          </Box>
        </Stack>
      </Body>
    </>
  );
}
