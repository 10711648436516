import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Card,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography
} from "@mui/material";
import Chip from "@mui/material/Chip";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import ImageUploading from "react-images-uploading";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import RichTextEditor from "react-rte";
import * as yup from "yup";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import Drawer from "../../components/Drawer";
import { categoryApi } from "../../store/categoryDetails/categoryList";
import { classGlobalApi } from "../../store/class/classListGlobal";
import { entryExitEditApi } from "../../store/entryExit/editEntryExit";
import { addhhwApi } from "../../store/hhw/addhhw";
import { getParticularhhwAPI, resetgetparticularhhw } from "../../store/hhw/gethhw";
import { subjectGlobalApi } from "../../store/subject/subjectListGlobal";
import {
  uploadHandoutsApi
} from "../../store/upload/uploadHandouts";
import { uploadImageApi } from "../../store/upload/uploadImage";
import BackdropUI from "../../ui/Backdrop";
import { EdithhwApi } from "../../store/hhw/edithhw";
import { topicsGlobalApi } from "../../store/topics/topicsListGlobal";
import { getParticularcompetitionAPI, resetgetparticularcompetition } from "../../store/competition/getcompetition";
import { EditcompetitionApi } from "../../store/competition/editcompetition";
import { addchanneltypeApi } from "../../store/channelType/addchanneltype";
import { EditchanneltypeApi, resetChannelType } from "../../store/channelType/editchannelType";
import { getParticularchanneltypeAPI } from "../../store/channelType/getchannel";
import { EditchannelApi } from "../../store/channel/editChannelList";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const schema = yup
  .object({
    type_name: yup.string().required("Please provide channel type name").trim(),
    type_name_hi: yup.string().required("Please submit your channel type name in hindi "),
  })
  .required();

export default function EditChannelType() {
  const theme = useTheme();
  const [fileUploadLoader, setFileUploadLoader] = useState(false);

  const shortVideoEdit = useSelector((state) => state?.edithhw);
  const shortVideoAdd = useSelector((state) => state?.addchanneltype);
  const shortVideoDetail = useSelector((state) => state?.getparticularDetailschanneltype);
  const { result, loiding } = shortVideoDetail;


  const dispatch = useDispatch();
  const refInput = useRef(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    control,
    setValue
  } = useForm({ resolver: yupResolver(schema), defaultValues: {} });
  const params = useParams();
  const navigate = useNavigate();
  useEffect(() => {

    return () => {
      dispatch(resetChannelType());

    }
  }, [])
  useEffect(() => {

    if (params.id) {
      dispatch(getParticularchanneltypeAPI(params.id));
    }
  }, []);


  const handleUpdateForm = async (data) => {
    if (params.id) {
      let obj = { ...data, id: params.id };
      await dispatch(EditchanneltypeApi(obj));
      if (!shortVideoEdit?.loading) {
        navigate("/channel-type");

      }
    } else {
      await dispatch(addchanneltypeApi(data));
      if (!shortVideoAdd?.loading) {
        navigate("/channel-type");
      }
    }
  };


  useEffect(() => {
    let defalutValues = {};
    console.log("hkjhjk", result)
    defalutValues["type_name"] = result?.type_name;
    defalutValues["type_name_hi"] = result?.type_name_hi;
    reset(defalutValues);
  }, [result, reset]);

  return (
    <>
      <Appbar />
      <Drawer />
      <Body>
        <form onSubmit={handleSubmit(handleUpdateForm)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card>

                <Typography>Details</Typography>
                <Stack spacing={2} my={2}>
                  <Divider />
                  <>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Channel Type Name</InputLabel>
                        <OutlinedInput
                          {...register("type_name")}
                          label="Channel Type Name"
                          notched
                          id="outlined-adornment-email"
                          error={errors?.type_name?.message}
                          defaultValue={result.type_name}
                        />
                        <FormHelperText error>
                          {errors?.type_name?.message}
                        </FormHelperText>
                      </FormControl>

                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Channel Type Name Hindi</InputLabel>
                        <OutlinedInput
                          {...register("type_name_hi")}
                          label="Channel Type Name Hindi"
                          notched
                          id="outlined-adornment-email"
                          error={errors?.type_name_hi?.message}
                          defaultValue={result.type_name_hi}
                        />
                        <FormHelperText error>
                          {errors?.type_name_hi?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                  </>
                  {/* } */}
                </Stack>
              </Card>
              <Button
                type="button"
                sx={{ float: "right", mt: 2, ml: 2 }}
                variant="contained"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                sx={{ float: "right", mt: 2 }}
                variant="contained"
              >
                {params.id ? "Update" : "Create"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Body>
      <BackdropUI open={fileUploadLoader} />
    </>
  );
}
