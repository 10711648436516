import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Card,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography
} from "@mui/material";
import Chip from "@mui/material/Chip";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import ImageUploading from "react-images-uploading";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import RichTextEditor from "react-rte";
import * as yup from "yup";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import Drawer from "../../components/Drawer";
import { categoryApi } from "../../store/categoryDetails/categoryList";
import { classGlobalApi } from "../../store/class/classListGlobal";
import { entryExitEditApi } from "../../store/entryExit/editEntryExit";
import { addhhwApi } from "../../store/hhw/addhhw";
import { getParticularhhwAPI, resetgetparticularhhw } from "../../store/hhw/gethhw";
import { subjectGlobalApi } from "../../store/subject/subjectListGlobal";
import {
  uploadHandoutsApi
} from "../../store/upload/uploadHandouts";
import { uploadImageApi } from "../../store/upload/uploadImage";
import BackdropUI from "../../ui/Backdrop";
import { EdithhwApi } from "../../store/hhw/edithhw";
import { topicsGlobalApi } from "../../store/topics/topicsListGlobal";
import { getParticularcompetitionAPI, resetgetparticularcompetition } from "../../store/competition/getcompetition";
import { EditcompetitionApi } from "../../store/competition/editcompetition";
import { addchanneltypeApi } from "../../store/channelType/addchanneltype";
import { EditchanneltypeApi, resetChannelType } from "../../store/channelType/editchannelType";
import { channeltypeApi } from "../../store/channelType/channelTypeList";
import { addchannelcodeApi } from "../../store/channelCode/addChannelCode";
import { EditchannelcodeApi, resetchannelcode } from "../../store/channelCode/editChannelCode";
import { getParticularchannelcodeAPI, resetgetparticularchannelcode } from "../../store/channelCode/getChannelCodeById";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const schema = yup
  .object({
    channel_code: yup.string().required("Please provide channel code").trim()
      // .min(6, 'Phone must be at least 6 characters')
      .length(6, 'Code number must be at most 6 characters')
      .matches(/^[^!@#$%^&*+=<>:;|~]*$/, {
        message: 'No symbols should be there in channel code'
      })
    ,

    channel_code_type: yup.number().required(),
    channel_code_name_hi: yup.string().required("Please submit channel code name hindi"),
    channel_code_name: yup.string().required("Please submit channel code name"),
  })
  .required();

export default function EditChannelCode() {
  const theme = useTheme();
  const [fileUploadLoader, setFileUploadLoader] = useState(false);
  const channelTypeListData = useSelector((state) => state?.channelTypeListData);

  const { result: classList, loading: classLoading } = useSelector((state) => state?.classGlobal);
  const { result: subjectList, loading: subjectLoading } = useSelector((state) => state?.subjectGlobal);
  const shortVideoEdit = useSelector((state) => state?.editChannelCode);
  const shortVideoAdd = useSelector((state) => state?.addchannelcode);
  const shortVideoDetail = useSelector((state) => state?.getparticularDetailschannelcode);
  const uploadImage = useSelector((state) => state?.uploadImage);
  const { result, loiding } = shortVideoDetail;
  const {
    result: {
      channel_code,
      video_url,
      mobile_number,
      duration: timeDuration,
      points,
      meta_tags,
      // topics,
    },
  } = shortVideoDetail;

  const dispatch = useDispatch();
  const refInput = useRef(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    control,
    setValue
  } = useForm({ resolver: yupResolver(schema), defaultValues: {} });
  const uploadHandouts = useSelector((state) => state?.uploadHandouts);
  const { result: topicsList, loading: topicsLoading } = useSelector((state) => state?.topicsGlobal);

  const [type, setType] = React.useState([]);
  const params = useParams();
  const navigate = useNavigate();
  const categories = useSelector((state) => state?.categories);
  useEffect(() => {

    return () => {
      dispatch(resetchannelcode());
      dispatch(resetgetparticularchannelcode())
    }
  }, [])
  useEffect(() => {
    dispatch(channeltypeApi());
    if (params.id) {
      dispatch(getParticularchannelcodeAPI(params.id));
    }
  }, []);



  const handleUpdateForm = async (data) => {
    const newobj = {
      ...data, channel_code_type: data?.channel_code_type
    }

    console.log('newObj', newobj)
    if (params.id) {
      let obj = { ...newobj, id: params.id };
      await dispatch(EditchannelcodeApi(obj));
      if (!shortVideoEdit?.loading) {
        navigate("/channel-code");

      }
    } else {
      await dispatch(addchannelcodeApi(newobj));
      if (!shortVideoAdd?.loading) {
        navigate("/channel-code");
      }
    }
  };

  useEffect(() => {
    let defalutValues = {};
    defalutValues["channel_code"] = result?.channel_code;
    defalutValues["channel_code_name"] = result?.channel_code_name;
    defalutValues["channel_code_name_hi"] = result?.channel_code_name_hi;
    defalutValues["channel_code_type"] = result?.channel_code_type;
    reset(defalutValues);
  }, [result]);
  useEffect(() => {
    setType(channelTypeListData?.result?.result)
  }, [channelTypeListData]);
  return (
    <>
      <Appbar />
      <Drawer />
      <Body>
        <form onSubmit={handleSubmit(handleUpdateForm)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card>

                <Typography>Details</Typography>
                <Stack spacing={2} my={2}>
                  <Divider />
                  <>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Channel Code </InputLabel>
                        <OutlinedInput
                          {...register("channel_code")}
                          label="channel_code"
                          notched
                          id="outlined-adornment-email"
                          error={errors?.channel_code?.message}
                          defaultValue={result.channel_code}
                        />
                        <FormHelperText error>
                          {errors?.channel_code?.message}
                        </FormHelperText>
                      </FormControl>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-email" shrink>
                          Channel Type
                        </InputLabel>
                        <Controller
                          name="channel_code_type"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <Select
                              {...field}
                              value={watch('channel_code_type', '')}
                              onChange={(e) => {
                                const {
                                  target: { value },
                                } = e;
                                setValue('channel_code_type', value);
                                field.onChange(e);
                              }}
                              input={<OutlinedInput id="select-single-chip" label="Chip" />}
                              renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                  <Chip key={selected} label={type?.find(obj => obj.id === selected)?.type_name || ""} />
                                </Box>
                              )}
                              MenuProps={MenuProps}
                            >
                              {type?.map((obj) => (
                                <MenuItem
                                  key={obj.type_name}
                                  value={obj.id}
                                  style={getStyles(obj.type_name, [watch('channel_code_type', '')], theme)}
                                >
                                  {obj.type_name}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                        <FormHelperText error>
                          {errors?.channel_code_type?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Channel Code Name</InputLabel>
                        <OutlinedInput
                          {...register("channel_code_name")}
                          label="Channel Code Name "
                          notched
                          id="outlined-adornment-email"
                          error={errors?.channel_code_name?.message}
                          defaultValue={result.channel_code_name}
                        />
                        <FormHelperText error>
                          {errors?.channel_code_name?.message}
                        </FormHelperText>
                      </FormControl>

                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Channel Code Name Hindi</InputLabel>
                        <OutlinedInput
                          {...register("channel_code_name_hi")}
                          label="Channel Code Name Hindi"
                          notched
                          id="outlined-adornment-email"
                          error={errors?.channel_code_name_hi?.message}
                          defaultValue={result.channel_code_name_hi}
                        />
                        <FormHelperText error>
                          {errors?.channel_code_name_hi?.message}
                        </FormHelperText>
                      </FormControl>

                    </Stack>
                  </>
                  {/* } */}
                </Stack>
              </Card>
              <Button
                type="button"
                sx={{ float: "right", mt: 2, ml: 2 }}
                variant="contained"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                sx={{ float: "right", mt: 2 }}
                variant="contained"
              >
                {params.id ? "Update" : "Create"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Body>
      <BackdropUI open={fileUploadLoader} />
    </>
  );
}
