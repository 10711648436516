import React from 'react'
import { DataGrid } from "@mui/x-data-grid";

export default function DataGridComponent(props) {
    return (
        <>
            <DataGrid
                disableSelectionOnClick
                disableRowSelectionOnClick
                pageSizeOptions={[5, 10, 20, 50, 100]}
                pagination
                getRowHeight={() => 'auto'}
                disableColumnSelector
                disableDensitySelector
                paginationMode="server"
                filterMode="server"
                sortingMode="server"
                {...props}
                sx={{
                    ...props.sx,
                    height: 700,
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                        outline: "none !important",
                    },
                    "& ::-webkit-scrollbar": {
                        width: "10px",
                        height: "10px"
                    },
                    "& ::-webkit-scrollbar-track": {
                        backgroundColor: "#f5f5f5"
                    },
                    "& ::-webkit-scrollbar-thumb": {
                        borderRadius: "10px",
                        boxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
                        backgroundColor: "#f5f5f5"
                    }
                }}
            />
        </>
    )
}