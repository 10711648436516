import { combineReducers } from "@reduxjs/toolkit";
import { analyticsGraphReducer } from "./analytics/analyticsGraphDetail";
import { analyticsTableReducer } from "./analytics/analyticsList";
import { analiticsFilterReducer } from "./analytics/filters";
import { authOtpViaEmailReducer } from "./auth/authOtpViaEmail";
import { authOtpViaSMSReducer } from "./auth/authOtpViaSMS";
import { forgetPasswordViaEmailReducer } from "./auth/forgetPasswordViaEmail";
import { forgetPasswordViaSMSReducer } from "./auth/forgetPasswordViaSMS";
import { loginReducer } from "./auth/login";
import { resetPasswordReducer } from "./auth/resetPassword";
import { resetPasswordVerifyOtpReducer } from "./auth/resetPasswordVerifyOtp";
import { verifyAuthOtpEmailReducer } from "./auth/verifyAuthOtpEmail";
import { verifyAuthOtpSMSReducer } from "./auth/verifyAuthOtpSMS";
import { getAllCatReducer } from "./category/getAllCat";
import { notSelectedCategoryReducer } from "./category/notSelectedCategory";
import { categoryAddReducer } from "./categoryDetails/categoryAdd";
import { categoryDeleteReducer } from "./categoryDetails/categoryDelete";
import { categoryDetailsReducer } from "./categoryDetails/categoryDetail";
import { categoryEditReducer } from "./categoryDetails/categoryEdit";
import { categoryReducer } from "./categoryDetails/categoryList";
import { splashScreenAddReducer } from "./cms/addSplashScreen";
import { splashScreenDeleteReducer } from "./cms/deleteSplashScreen";
import { pageEditReducer } from "./cms/editPage";
import { splashScreenEditReducer } from "./cms/editSplashScreen";
import { pageDetailsReducer } from "./cms/getAboutUs";
import { splashScreenDetailsReducer } from "./cms/getSplashScreen";
import { manageSplashScreenReducer } from "./cms/manageSplashScreen";
import { courseAddReducer } from "./courses/addCourse";
import { courseSectionAddReducer } from "./courses/addSection";
import { courseDetailsReducer } from "./courses/courseDetails";
import { courseReducer } from "./courses/coursesList";
import { courseDeleteReducer } from "./courses/deleteCourse";
import { deleteSectionReducer } from "./courses/deleteSection";
import { courseEditReducer } from "./courses/editCourse";
import { getScromReducer } from "./courses/getScrom";
import { getCourseSectionReducer } from "./courses/getSection";
import { courseSectionDetailsAddReducer } from "./courses/sectionDetails";
import { courseSubSectionAddReducer } from "./courses/sub-section/addSubSection";
import { courseSubSectionDeleteReducer } from "./courses/sub-section/deleteSubSection";
import { getCourseSubSectionReducer } from "./courses/sub-section/getSubSection";
import { updateSubSectionReducer } from "./courses/sub-section/updateSubSection";
import { addUnitReducer } from "./courses/unit/addUnit";
import { deleteUnitReducer } from "./courses/unit/deleteUnit";
import { getUnitReducer } from "./courses/unit/getUnit";
import { getUnitListReducer } from "./courses/unit/getUnitList";
import { publishUnitReducer } from "./courses/unit/publishUnit";
import { updateUnitReducer } from "./courses/unit/updateUnit";
import { updateSectionReducer } from "./courses/updateSection";
import { addVideoUnitReducer } from "./courses/video-unit/addVideoUnit";
import { recentUserCountReducer } from "./dashboard/recentUserCount";
import { userCountReducer } from "./dashboard/userCount";
import { entryExitAddReducer } from "./entryExit/addEntryExit";
import { entryExitDeleteReducer } from "./entryExit/deleteEntryExit";
import { entryExitDetailsReducer } from "./entryExit/detailEntryExit";
import { entryExitEditReducer } from "./entryExit/editEntryExit";
import { entryExitReducer } from "./entryExit/entryExitList";
import { faqAddReducer } from "./faq/faqAdd";
import { faqDeleteReducer } from "./faq/faqDelete";
import { faqDetailsReducer } from "./faq/faqDetails";
import { faqEditReducer } from "./faq/faqEdit";
import { faqReducer } from "./faq/faqList";
import { hhwApproveReducer } from "./hhw/approveHhw";
import { hhwReducer } from "./hhw/hhwList";
import { impactStoriesReducer } from "./impactStory/impactStoriesList";
import { storiesDetailsReducer } from "./impactStory/storyDetails";
import { pushNotificationReducer } from "./notifications/pushNotification";
import { partnerAddReducer } from "./partner/addPartner";
import { partnerDeleteReducer } from "./partner/deletePartner";
import { partnerEditReducer } from "./partner/editPartner";
import { partnerDetailsReducer } from "./partner/partnerDetails";
import { partnerReducer } from "./partner/partnerList";
import { pblApproveReducer } from "./pbl/approvePbl";
import { pblReducer } from "./pbl/pblList";
import { podcastAddReducer } from "./podcast/addPodcast";
import { uploadBulkPodcastReducer } from "./podcast/bulkpodcast";
import { podcastDeleteReducer } from "./podcast/deletePodcast";
import { podcastEditReducer } from "./podcast/editPodcast";
import { podcastDetailsReducer } from "./podcast/podcastDetails";
import { podcastReducer } from "./podcast/podcastList";
import { addQnsBankReducer } from "./question-bank/add";
import { bulkUploadReducer } from "./question-bank/bulkUpload";
import { deleteQnsBankcReducer } from "./question-bank/delete";
import { detailsQnsBankReducer } from "./question-bank/detail";
import { editQnsBankReducer } from "./question-bank/edit";
import { qnsBankListReducer } from "./question-bank/list";
import { quizAddReducer } from "./quizzes/addQuiz";
import { uploadBulkQuizReducer } from "./quizzes/bulkQuiz";
import { quizDeleteReducer } from "./quizzes/deleteQuiz";
import { quizEditReducer } from "./quizzes/editQuiz";
import { quizDetailsReducer } from "./quizzes/quizDetail";
import { quizListReducer } from "./quizzes/quizList";
import { schoolResourceAddReducer } from "./schoolResources/addSchoolResource";
import { uploadBulkSchoolResourceReducer } from "./schoolResources/bulkSchoolResource";
import { schoolResourceDeleteReducer } from "./schoolResources/deleteSchoolResource";
import { schoolResourceEditReducer } from "./schoolResources/editSchoolResource";
import { schoolResourceDetailsReducer } from "./schoolResources/schoolResourceDetails";
import { schoolResourceReducer } from "./schoolResources/schoolResourceList";
import { uploadBulkShortVideoReducer } from "./shortVideo/bulkShortVideo";
import { shortVideoDeleteReducer } from "./shortVideo/deleteShortVideo";
import { shortVideoEditReducer } from "./shortVideo/editShortVideo";
import { shortVideoDetailsReducer } from "./shortVideo/shortVideoDetail";
import { shortVideoReducer } from "./shortVideo/shortVideoList";
import { sundayPollAddReducer } from "./sundayPolls/addSundayPoll";
import { sundayPollEditReducer } from "./sundayPolls/editSundayPoll";
import { sundayPollResultsReducer } from "./sundayPolls/result";
import { sundayPollDetailsReducer } from "./sundayPolls/sundayPollDetail";
import { sundayPollReducer } from "./sundayPolls/sundayPollsList";
import { supportReducer } from "./support/supportList";
import { teachingVideoAddReducer } from "./teachingVideo/addTeachingVideo";
import { uploadBulkTeachingVideoReducer } from "./teachingVideo/bulkTeachingVideo";
import { teachingVideoDeleteReducer } from "./teachingVideo/deleteTeachingVideo";
import { teachingVideoEditReducer } from "./teachingVideo/editTeachingVideo";
import { teachingVideoDetailsReducer } from "./teachingVideo/shortTeachingDetail";
import { teachingVideoReducer } from "./teachingVideo/teachingVideoList";
import { tlmApproveReducer } from "./tlm/approveTlm";
import { tlmReducer } from "./tlm/tlmList";
import { uploadHandoutsReducer } from "./upload/uploadHandouts";
import { uploadImageReducer } from "./upload/uploadImage";
import { uploadSpeakerImageReducer } from "./upload/uploadSpeakerImage";
import { userStatusReducer } from "./user/statusChange";
import { userReducer } from "./user/userList";
import { webinarAddReducer } from "./webinar/addWebinar";
import { webinarDeleteReducer } from "./webinar/deleteWebinar";
import { webinarEditReducer } from "./webinar/editWebinar";
import { webinarDetailsReducer } from "./webinar/webinarDetail";
import { webinarReducer } from "./webinar/webinarList";

import { classAddReducer } from "./class/classAdd";
import { classDeleteReducer } from "./class/classDelete";
import { classDetailsReducer } from "./class/classDetail";
import { classEditReducer } from "./class/classEdit";
import { classReducer } from "./class/classList";

import { subjectAddReducer } from "./subject/subjectAdd";
import { subjectDeleteReducer } from "./subject/subjectDelete";
import { subjectDetailsReducer } from "./subject/subjectDetail";
import { subjectEditReducer } from "./subject/subjectEdit";
import { subjectReducer } from "./subject/subjectList";

import { notificationReducer } from "./notifications/notificationsList";
import { settingAddReducer } from "./settings/settingAdd";
import { settingDetailsReducer } from "./settings/settingDetail";
import { settingEditReducer } from "./settings/settingEdit";
import { settingReducer } from "./settings/settingList";

import { difficultyLevelAddReducer } from "./difficulty/difficultyAdd";
import { difficultyLevelDeleteReducer } from "./difficulty/difficultyDelete";
import { difficultyLevelDetailsReducer } from "./difficulty/difficultyDetail";
import { difficultyLevelEditReducer } from "./difficulty/difficultyEdit";
import { difficultyLevelReducer } from "./difficulty/difficultyList";
import { certificateReducer } from "./settings/certificate";
import { particularcertificateReducer } from "./settings/getParticularCertificate";
import { topicsAddReducer } from "./topics/topicsAdd";
import { topicsDeleteReducer } from "./topics/topicsDelete";
import { topicsDetailsReducer } from "./topics/topicsDetail";
import { topicsEditReducer } from "./topics/topicsEdit";
import { topicsReducer } from "./topics/topicsList";
import { uploadCertificateReducer } from "./upload/uploadCertificates";
import { webinarInterestedUserReducer } from "./webinar/interestedUsers";
import { addTLMreducer } from "./tlm/addTlm";
import { EditTLMreducer } from "./tlm/editTlm";
import { getparticularDetailsReducer } from "./tlm/getParticular";
import { hhwDeleteReducer } from "./hhw/deletehhw";
import { addhhwreducer } from "./hhw/addhhw";
import { getparticularDetailshhwReducer } from "./hhw/gethhw";
import { Edithhwreducer } from "./hhw/edithhw";
import { pblDeleteReducer } from "./pbl/deletepbl";
import { addpblreducer } from "./pbl/addpbl";
import { Editpblreducer } from "./pbl/editpbl";
import { getparticularDetailspblReducer } from "./pbl/getparticularpbl";
import { cohortReducer } from "./notification-cohort/getAllCohort";
import { cohortUserReducer } from "./notification-cohort/cohortUserList";
import { addcohortreducer } from "./notification-cohort/creatCohort";
import { cohortDetailsReducer } from "./notification-cohort/notificationById";
import { schoolTypeReducer } from "./school/schoolTypeList";
import { contentReportTableReducer } from "./analytics/contentReport"
import { userContentReportTableReducer } from "./analytics/userContentReport"
import { classGlobalReducer } from "./class/classListGlobal"
import { subjectGlobalReducer } from "./subject/subjectListGlobal"
import { topicsGlobalReducer } from "./topics/topicsListGlobal"
import { updatePublishUnpublishReducer } from "./admin/updatePublishUnpublish";
import { teachingCategoryReducer } from "./teachingCategory/teachingCategoryList";
import { teachingCategoryAddReducer } from "./teachingCategory/addTeachingCategory";
import { teachingCategoryDeleteReducer } from "./teachingCategory/deleteTeachingCategory";
import { teachingCategoryDetailsReducer } from "./teachingCategory/detailTeachingCategory";
import { teachingCategoryEditReducer } from "./teachingCategory/editTeachingCategory";
import { channeltypeReducer } from "./channelType/channelTypeList";
import { addchanneltypereducer } from "./channelType/addchanneltype";
import { getparticularDetailschanneltypeReducer } from "./channelType/getchannel";
import { Editchanneltypereducer } from "./channelType/editchannelType";
import { channelcodeReducer } from "./channelCode/channelcodeList";
import { addchannelcodereducer } from "./channelCode/addChannelCode";
import { Editchannelcodereducer } from "./channelCode/editChannelCode";
import { channelReducer } from "./channel/getchannelList";
import { getparticularDetailschannelReducer } from "./channel/channelById";
import { assignedObjectListreducer } from "./channel/assignedObjectsListing";
import { moduleListingreducer } from "./channel/moduleListing";
import { assignObjectToChannelreducer } from "./channel/assignObjectToChannel";
import { unAssignObjectFromChannelreducer } from "./channel/unAssignObjectFromChannel";
import { managechannelBannerReducer } from "./channel-banner/ManageChannelBanner";
import { channelBannerDeleteReducer } from "./channel-banner/deleteChannelBanner";
import { channelBannerAddReducer } from "./channel-banner/addChannelBanner";
import { channelBannerEditReducer } from "./channel-banner/editChannelBanner";
import { channelBannerDetailsReducer } from "./channel-banner/getChannelBannerDetail";
import { getparticularDetailschannelcodeReducer } from "./channelCode/getChannelCodeById";
const rootReducer = combineReducers({
  // auth
  login: loginReducer,
  authOtpViaEmail: authOtpViaEmailReducer,
  authOtpViaSMS: authOtpViaSMSReducer,
  verifyAuthOtpEmail: verifyAuthOtpEmailReducer,
  verifyAuthOtpSMS: verifyAuthOtpSMSReducer,
  forgetPasswordViaEmail: forgetPasswordViaEmailReducer,
  forgetPasswordViaSMS: forgetPasswordViaSMSReducer,
  resetPasswordVerifyOtp: resetPasswordVerifyOtpReducer,
  resetPassword: resetPasswordReducer,
  // category
  getAllCat: getAllCatReducer,
  notSelectedCategory: notSelectedCategoryReducer,
  //user
  user: userReducer,
  userStatus: userStatusReducer,
  //webinar
  webinar: webinarReducer,
  webinarEdit: webinarEditReducer,
  webinarDelete: webinarDeleteReducer,
  webinarAdd: webinarAddReducer,
  webinarDetails: webinarDetailsReducer,
  webinarInterestedUser: webinarInterestedUserReducer,
  //Support
  support: supportReducer,
  //Short Video
  shortVideo: shortVideoReducer,
  shortVideoEdit: shortVideoEditReducer,
  shortVideoDelete: shortVideoDeleteReducer,
  shortVideoDetail: shortVideoDetailsReducer,
  bulkShortVideo: uploadBulkShortVideoReducer,
  //Teaching Video
  teachingVideo: teachingVideoReducer,
  teachingVideoEdit: teachingVideoEditReducer,
  teachingVideoAdd: teachingVideoAddReducer,
  teachingVideoDelete: teachingVideoDeleteReducer,
  teachingVideoDetail: teachingVideoDetailsReducer,
  uploadBulkTeachingVideo: uploadBulkTeachingVideoReducer,

  //School Resource
  schoolResource: schoolResourceReducer,
  schoolResourceAdd: schoolResourceAddReducer,
  schoolResourceEdit: schoolResourceEditReducer,
  schoolResourceDelete: schoolResourceDeleteReducer,
  schoolResourceDetail: schoolResourceDetailsReducer,
  bulkSchoolResource: uploadBulkSchoolResourceReducer,

  // Entry Exit Question
  entryExit: entryExitReducer,
  entryExitAdd: entryExitAddReducer,
  entryExitEdit: entryExitEditReducer,
  entryExitDelete: entryExitDeleteReducer,
  entryExitDetails: entryExitDetailsReducer,

  //Podcast
  podcast: podcastReducer,
  podcastEdit: podcastEditReducer,
  podcastDelete: podcastDeleteReducer,
  podcastAdd: podcastAddReducer,
  podcastDetails: podcastDetailsReducer,
  bulkPodcast: uploadBulkPodcastReducer,
  //faq
  faq: faqReducer,
  faqEdit: faqEditReducer,
  faqAdd: faqAddReducer,
  faqDelete: faqDeleteReducer,
  faqDetail: faqDetailsReducer,
  //courses
  courses: courseReducer,
  coursesEdit: courseEditReducer,
  coursesAdd: courseAddReducer,
  coursesDetails: courseDetailsReducer,
  coursesDelete: courseDeleteReducer,
  courseSectionAdd: courseSectionAddReducer,
  getCourseSection: getCourseSectionReducer,
  deleteSection: deleteSectionReducer,
  updateSection: updateSectionReducer,
  courseSubSectionAdd: courseSubSectionAddReducer,
  getCourseSubSection: getCourseSubSectionReducer,
  courseSubSectionDelete: courseSubSectionDeleteReducer,
  updateSubSection: updateSubSectionReducer,
  getUnit: getUnitReducer,
  updateUnit: updateUnitReducer,
  deleteUnit: deleteUnitReducer,
  addUnit: addUnitReducer,
  publishUnit: publishUnitReducer,
  addVideoUnit: addVideoUnitReducer,
  getUnitList: getUnitListReducer,
  courseSectionDetailsAdd: courseSectionDetailsAddReducer,

  // courseDetailReset: courseDetailResetReducer,
  //dashboard
  userCount: userCountReducer,
  recentUserCount: recentUserCountReducer,
  //CMs
  cmsEdit: pageEditReducer,
  pageDetails: pageDetailsReducer,
  manageSplashScreen: manageSplashScreenReducer,
  splashScreenAdd: splashScreenAddReducer,
  splashScreenDetails: splashScreenDetailsReducer,
  splashScreenEdit: splashScreenEditReducer,
  deleteSplashScreen: splashScreenDeleteReducer,
  //categories
  categories: categoryReducer,
  categoryDetails: categoryDetailsReducer,
  categoryEdit: categoryEditReducer,
  categoryAdd: categoryAddReducer,
  categoryDelete: categoryDeleteReducer,
  //image-upload
  uploadImage: uploadImageReducer,
  uploadSpeakerImage: uploadSpeakerImageReducer,
  uploadHandouts: uploadHandoutsReducer,
  uploadCertificate: uploadCertificateReducer,
  // Sunday Polls
  sundayPollAdd: sundayPollAddReducer,
  sundayPollEdit: sundayPollEditReducer,
  sundayPollDetail: sundayPollDetailsReducer,
  sundayPollList: sundayPollReducer,
  sundayPollResult: sundayPollResultsReducer,
  //impact-stories
  impactStories: impactStoriesReducer,
  storyDetails: storiesDetailsReducer,
  //scrom
  scrom: getScromReducer,
  //partner
  partner: partnerReducer,
  partnerEdit: partnerEditReducer,
  partnerDelete: partnerDeleteReducer,
  partnerAdd: partnerAddReducer,
  partnerDetails: partnerDetailsReducer,
  //Quiz
  quizList: quizListReducer,
  quizEdit: quizEditReducer,
  quizDelete: quizDeleteReducer,
  quizAdd: quizAddReducer,
  quizDetails: quizDetailsReducer,
  bulkQuiz: uploadBulkQuizReducer,
  //teaching-kits
  pblList: pblReducer,
  pblApprove: pblApproveReducer,
  pblDelete: pblDeleteReducer,
  addPbl: addpblreducer,
  editPbl: Editpblreducer,
  getparticularDetailspbl: getparticularDetailspblReducer,

  tlmList: tlmReducer,
  tlmApprove: tlmApproveReducer,
  addTLM: addTLMreducer,
  getparticularDetails: getparticularDetailsReducer,
  EditTLM: EditTLMreducer,
  hhwList: hhwReducer,
  hhwApprove: hhwApproveReducer,
  hhwDelete: hhwDeleteReducer,
  addhhw: addhhwreducer,
  getparticularDetailshhw: getparticularDetailshhwReducer,
  edithhw: Edithhwreducer,
  // question bank
  addQnsQnsBank: addQnsBankReducer,
  deleteQnsBank: deleteQnsBankcReducer,
  detailsQnsBank: detailsQnsBankReducer,
  editQnsBank: editQnsBankReducer,
  qnsBankList: qnsBankListReducer,
  bulkUpload: bulkUploadReducer,

  // Notification
  pushNotification: pushNotificationReducer,
  notificationList: notificationReducer,

  //analytics
  analyticsGraph: analyticsGraphReducer,
  analyticsTable: analyticsTableReducer,
  analyticsFilter: analiticsFilterReducer,

  //class
  classDelete: classDeleteReducer,
  classAdd: classAddReducer,
  classDetails: classDetailsReducer,
  class: classReducer,
  classEdit: classEditReducer,

  //school type
  schoolType: schoolTypeReducer,

  //subject
  subjectDetails: subjectDetailsReducer,
  subject: subjectReducer,
  subjectDelete: subjectDeleteReducer,
  subjectEdit: subjectEditReducer,
  subjectAdd: subjectAddReducer,

  //settings
  settingList: settingReducer,
  settingEdit: settingEditReducer,
  settingAdd: settingAddReducer,
  settingDetails: settingDetailsReducer,
  certificatesDetails: certificateReducer,
  particularcertificate: particularcertificateReducer,

  //difficulty-level
  difficultyLevelAdd: difficultyLevelAddReducer,
  difficultyLevelDelete: difficultyLevelDeleteReducer,
  difficultyLevelDetails: difficultyLevelDetailsReducer,
  difficultyLevelEdit: difficultyLevelEditReducer,
  difficultyLevel: difficultyLevelReducer,

  //topics
  topicsAdd: topicsAddReducer,
  topicsDelete: topicsDeleteReducer,
  topicsDetails: topicsDetailsReducer,
  topicsEdit: topicsEditReducer,
  topics: topicsReducer,

  //notification-cohort
  cohortList: cohortReducer,
  cohortUser: cohortUserReducer,
  addcohort: addcohortreducer,
  cohortDetails: cohortDetailsReducer,

  contentReportTable: contentReportTableReducer,
  userContentReportTable: userContentReportTableReducer,

  classGlobal: classGlobalReducer,
  subjectGlobal: subjectGlobalReducer,
  topicsGlobal: topicsGlobalReducer,
  updatePublishUnpublish: updatePublishUnpublishReducer,

  // channel banner
  managechannelBanner:managechannelBannerReducer,
  channelBannerDelete:channelBannerDeleteReducer,
  channelBannerAdd:channelBannerAddReducer,
  channelBannerEdit:channelBannerEditReducer,
  channelBannerDetails:channelBannerDetailsReducer,

  // teaching category
  teachingCategory: teachingCategoryReducer,
  teachingCategoryAdd: teachingCategoryAddReducer,
  teachingCategoryDelete: teachingCategoryDeleteReducer,
  teachingCategoryDetails: teachingCategoryDetailsReducer,
  teachingCategoryEdit: teachingCategoryEditReducer,
  channelTypeListData: channeltypeReducer,
  addchanneltype: addchanneltypereducer,
  editchanneltype: Editchanneltypereducer,
  channelList: channelReducer,
  channelcodeList: channelcodeReducer,
  addchannelcode: addchannelcodereducer,
  editChannelCode: Editchannelcodereducer,
  getparticularDetailschannel: getparticularDetailschannelReducer,
  assignedObjectList: assignedObjectListreducer,
  moduleListing: moduleListingreducer,
  assignObjectToChannel: assignObjectToChannelreducer,
  unAssignObjectFromChannel: unAssignObjectFromChannelreducer,
  getparticularDetailschanneltype: getparticularDetailschanneltypeReducer,
  getparticularDetailschannelcode: getparticularDetailschannelcodeReducer
});

export default rootReducer;
